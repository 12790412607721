<template>
    <ul class="flex items-center justify-around text-gray-600 text-sm">
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_rakenskapsar' }">
            <router-link :to="{ name: 'lopande_rakenskapsar', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Räkenskapsår</router-link>
        </li>
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_huvudbok' }">
            <router-link :to="{ name: 'lopande_huvudbok', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Huvudbok</router-link>
        </li>
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_verifikationer' }">
            <router-link :to="{ name: 'lopande_verifikationer', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Verifikationer</router-link>
        </li>
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_resultatrakning' }">
            <router-link :to="{ name: 'lopande_resultatrakning', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Resultaträkning</router-link>
        </li>
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_balansrakning' }">
            <router-link :to="{ name: 'lopande_balansrakning', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Balansräkning</router-link>
        </li>
        <!-- <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_bilagor' }">
            <router-link :to="{ name: 'lopande_bilagor', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Bilagor</router-link>
        </li> -->
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_accrual' }">
            <router-link :to="{ name: 'lopande_accrual', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Periodisering</router-link>
        </li>
        <li class="bg-white rounded-md py-2 px-4 transition-colors" :class="{ 'bg-blue-100': $route.name === 'lopande_import' }">
            <router-link :to="{ name: 'lopande_import', params: { clientId: clientId }, query: $route.query }" class="text-gray-900">Import</router-link>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        clientId: {
            type: String,
            default: null,
        },
    },
};
</script>
